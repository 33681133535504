// import logo from './logo.svg';
import Notfound from './pages/NotFound.js'
import About from './pages/About.js'
import Landing from './pages/Landing2.js'
import Navbar from './components/Navbar.js';

import Hypergraph1 from './pages/Hypergraph1'
import Hypergraph2 from './pages/Hypergraph2'
import PolyFrame from './pages/PolyFrame'
import FreeKit from './pages/FreeKit'
import PG from './pages/P'
import Demo from './pages/demo'
import Demo2 from './pages/demo_vector'

// import ReactMarkdown from 'react-markdown'
// import remarkGfm from 'remark-gfm';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';





const md1 = `
# Swizzle.fun
Material Kits for Game Development and virtual production.


`

// 9 pages - 1 landing, 9 extravaganzas, newsletter signup email list, material service 
function App() {
  return (
    <div data-theme="dark" className="App">
      <Navbar/>
      <Router>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/about" element={<About />} />
          <Route path="/Hypergraph1" element={<Hypergraph1/>} />
          <Route path="/Hypergraph2" element={<Hypergraph2/>} />
          <Route path="/PolyFrame" element={<PolyFrame/>} />
          <Route path="/FreeKit" element={<FreeKit/>} />
          <Route path="/P" element={<PG/>} />
          <Route path="*" element={<Notfound />} />
          <Route path="/demo" element={<Demo />} />
          <Route path="/demo-vector" element={<Demo2 />} />
        </Routes>
      </Router>
      {/* <ReactMarkdown children={md1} remarkPlugins={[remarkGfm]} /> */}
    </div>
  );
}

export default App;
