import React from "react";
import Clock from "../components/Clock"
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm';
import Accordion from '../components/Accordion';
import ReactPlayer from 'react-player'
import CarouselCard from "../components/CarouselCard";


const md1 = `
# Swizzle.fun
Material Kits for Game Development and virtual production.
*These are the materials you will not find anywhere else.*
`
const accordmarkdown = [
  `Primary
- one
- two
- three
`,
  `Secondary
#  heading 1:
Content for *section 1* in Markdown format
`,
  `Dirt
#  heading 1:
Content for *section 1* in Markdown format
`,
];
const videoSources = [
  '/vol12a_12.mp4',
  '/vol12a_59.mp4',
  '/vol12a_83.mp4',
  '/vol12a_89.mp4',
  '/vol12a_103.mp4',
];

export default function Landing() {
  return (
    <div>
      {/* <Clock /> */}

      {/* <div>
      <h1 class="text-center lg:text-9xl  mt-20 mb-5">Unique Material Libraries</h1>
        <h2 class="text-center my-5">
          Welcome to Swizzle.fun! We convert boring geometry into interesting surfaces.
        </h2>
        <div class="mx-auto text-center paragraph-narrow mb-20">
          <p >Our Material packs are SBSAR (Substance Designer) graphs , having them available as bitmaps would defeat the purpose and nullify our work. The <b>most special thing</b> about these materials is that they <b>do not come "as-is."</b> Primary, secondary, and tertiary colors; grunge, and other properties can all be adjusted to match <em>your artistic vision</em>.</p>

          <p >Most DCCs have a substance designer plugin to streamline the expression of materials and the application of textures. This is an excellent way to work! We were thrilled when we no longer needed to find and hook five or more textures into a shadergraph. If you already work this way, fantastic!</p>
          <p >Don't worry about buying anything from Adobe, <b>Substance Player</b> can be used to express these materials into bitmaps for all modern render pipelines — Freely!</p>
          <em>Thanks for visiting, we hope there is something here to inspire you!</em>
        </div>
      </div>

      <hr></hr> */}


      <div>
        <h1 class="text-center lg:text-9xl  mt-20 mb-5">Ceramics</h1>
        <h2 class="text-center my-5">
          Buy it 🪙, use it 🛠️, love it 💖— come back for more 😀
        </h2>
        <a href="https://rh2.gumroad.com/l/ceramics">
        <div class="bigvid " >
          <video class="bigvid hd2wide"  autoPlay={true} playsInline={true} muted={true} loop={true} src="/2024/CER_s.mp4" >
          </video>
          <div>
            <img alt="Swizzle into some great ceramic textures with a SBSAR that supports Unity, Unreal, and Godot. The PBR materials should also be compatible with Houdini, Cinema4d's Redshift, Blender, Maya, Vray, and Octane." class="text-upright m-5 opacity-40 hover:opacity-100" width="200px" src="/2024/engines_supported_white_transparent.svg"/>
          </div>
          <div class="text-overlay">
            <button class="btn-accent btn-lg  btn-alert rounded-md"> <b>⭐⭐⭐ 160 Marble/Ceramic Materials ⭐⭐⭐ </b></button>
          </div>
        </div>
        </a>

          
      </div>

      <div>
        <h1 class="text-center lg:text-9xl m-20">Our Best Materials</h1>
        <h2 class="text-center m-20">
          These are <em>exclusive</em> materials you will not find anywhere else.<br/>
          We specialize in detailed surfaces, panels, and FUI elements.<br/><br/>
        </h2>
      </div>

      <div class="grid md:grid-cols-2 xl:grid-cols-3">
      <a href="https://rh2.gumroad.com/l/hypergraph1">
        <div>
          <video class="bigvid" autoPlay={true} playsInline={true} muted={true} loop={true} src="/2024/hypergraph.mp4" >
          </video>
          <div class="glass sm:text-over p-10">
              <p class="pt-5 ">Offered Exclusively through Swizzle.fun — Boost your project with this pack of 400 Materials.
              </p>
              <button class="btn-accent btn-lg  btn-alert rounded-md">400 Materials - contact sheet </button>
          </div>
        </div>
      </a>

      <a href="https://rh2.gumroad.com/l/hypergraph2">
        <div >
          <video class="bigvid" autoPlay={true} playsInline={true} muted={true} loop={true} src="/2024/hypergraph2.mp4" >
          </video>
          <div class="glass sm:text-over p-10">
              <p class="pt-5 ">Offered Exclusively through Swizzle.fun — Boost your project with this pack of 100 Materials.
              </p>
              <button class="btn-accent btn-lg  btn-alert rounded-md">100 Materials - in a 5.7MB SBSAR</button>
          </div>
        </div>
      </a>

      <a href="https://rh2.gumroad.com/l/hypergraph3">
        <div >
          <video class="bigvid" autoPlay={true} playsInline={true} muted={true} loop={true} src="/2024/hypergraph3.mp4" >
          </video>
          <div class="glass sm:text-over p-10">
              <p class="pt-5 ">Offered Exclusively through Swizzle.fun — Boost your project with this pack of 55 Materials.
              </p>
              <button class="btn-accent btn-lg  btn-alert rounded-md">55 Materials - last update: 08/12/2024</button>
          </div>
        </div>
      </a>
      </div>


      {/* <h1 class="text-center lg:text-9xl m-20">Just Getting Started?</h1>
        <h2 class="text-center m-20">
          Midsize collections for you.<br/>
        </h2> */}


      {/* <div class="grid md:grid-cols-2 xl:grid-cols-3">

      <a href="https://rh2.gumroad.com/l/vol5">
        <div>
          <video class="bigvid" autoPlay={true} playsInline={true} muted={true} loop={true} src="/2024/vol5_2.mp4" >
          </video>
          <a href="/hypergraph1">
          <div class="glass sm:text-over p-10">

              <p class="pt-5 ">Vol 5. Spaceship details. Updated 3 times since release.
              </p>
              <button class="btn m-2 text-3xl ">$50</button>

          </div>
          </a>
        </div>
      </a>
      <a href="https://rh2.gumroad.com/l/vol6">
        <div >
          <video class="bigvid" autoPlay={true} playsInline={true} muted={true} loop={true} src="/2024/vol6.mp4" >
          </video>
          <div class="glass sm:text-over p-10">
            <a href="/hypergraph1">
              <p class="pt-5 ">Vol 6. More Electrical Panels.
              </p>
              <button class="btn m-2 text-3xl ">$50</button>
            </a>
          </div>
        </div>
      </a>
      <a href="https://rh2.gumroad.com/l/vol11">
        <div >
          <video class="bigvid" autoPlay={true} playsInline={true} muted={true} loop={true} src="/2024/vol11.mp4" >
          </video>
          <div class="glass sm:text-over p-10">
            <a href="/hypergraph1">
              <p class="pt-5 ">Vol 11. Based on textiles and patterns from the 13th-17th century.
              </p>
              <button class="btn m-2 text-3xl ">$50</button>
            </a>
          </div>
        </div>
      </a>
      </div> */}


      {/* <div class="mx-auto">
          <h1 class="text-center lg:text-9xl m-20">Our Future</h1>
          <h2 class="text-center ">
            Tell us what you like, what you dislike, and build with us.
            We're on <a href="https://www.linkedin.com/company/swizzle-fun" ><em>Linkedin</em> </a>
          </h2>
      </div> */}
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </div>


  );
}