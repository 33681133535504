import React from "react";
import Clock from "../components/Clock"
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm';
import Accordion from '../components/Accordion';
import ReactPlayer from 'react-player'
import CarouselCard from "../components/CarouselCard";


const md1 = `
# Swizzle.fun
Material Kits for Game Development and virtual production.
*These are the materials you will not find anywhere else.*
`
const accordmarkdown = [
  `Primary
- one
- two
- three
`,
  `Secondary
#  heading 1:
Content for *section 1* in Markdown format
`,
  `Dirt
#  heading 1:
Content for *section 1* in Markdown format
`,
];
const videoSources = [
  '/vol12a_12.mp4',
  '/vol12a_59.mp4',
  '/vol12a_83.mp4',
  '/vol12a_89.mp4',
  '/vol12a_103.mp4',
];

export default function Landing() {
  return (
    <div>
      {/* <Clock /> */}

      {/* <div>
      <h1 class="text-center lg:text-9xl  mt-20 mb-5">Unique Material Libraries</h1>
        <h2 class="text-center my-5">
          Welcome to Swizzle.fun! We convert boring geometry into interesting surfaces.
        </h2>
        <div class="mx-auto text-center paragraph-narrow mb-20">
          <p >Our Material packs are SBSAR (Substance Designer) graphs , having them available as bitmaps would defeat the purpose and nullify our work. The <b>most special thing</b> about these materials is that they <b>do not come "as-is."</b> Primary, secondary, and tertiary colors; grunge, and other properties can all be adjusted to match <em>your artistic vision</em>.</p>

          <p >Most DCCs have a substance designer plugin to streamline the expression of materials and the application of textures. This is an excellent way to work! We were thrilled when we no longer needed to find and hook five or more textures into a shadergraph. If you already work this way, fantastic!</p>
          <p >Don't worry about buying anything from Adobe, <b>Substance Player</b> can be used to express these materials into bitmaps for all modern render pipelines — Freely!</p>
          <em>Thanks for visiting, we hope there is something here to inspire you!</em>
        </div>
      </div>

      <hr></hr> */}


      <div>
        <h1 class="text-center lg:text-9xl  mt-20 mb-5">Vector Work</h1>
        <h2 class="text-center my-5">
          I like curves and dashed lines...
        </h2>


        <div class="bigvid " >
          <img src="/svg/2024/heart.svg"/>
        </div>
        <div class="bigvid " >
          <img src="/svg/2024/nautilus.svg"/>
        </div>

        <div class="grid grid-cols-3">
          <img src="/svg/2024/hexcell1.svg"/><img src="/svg/2024/hexcell2.svg"/><img src="/svg/2024/hexcell3.svg"/>
        </div> 


        <div class="bigvid " >
          <img src="/svg/2024/hexgen_python.svg"/>
        </div>

          
      </div>




      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </div>


  );
}